html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.top-left-image {
  width: 112px;
  height: 102px;
  margin: 20px 0 -78px -17px;
  overflow: hidden;
}

.bottom-right-image {
  margin-top: -50px;
  width: 112px;
  height: 102px;
  overflow: hidden;
}

.testimonial-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 55%  !important;
  margin: 45px auto;
  overflow: hidden;
}

.testimonial-slide {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden;
  width: 100%;
}


.description-container .testimonial-bottom-image {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -20px;
}

.column-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;



}

.column-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* display: flex;
    flex-direction: column; */
  width: 100%;
}

.img-testimonial {
  width: 60%;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
}

.slide-button {
  border-radius: 36px;
  background: #4285F4;
  box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
  color: #FFF;
  padding: 2px 10px;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.background-image-top-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background-image: url('../../../assets/inverted\ 1\ \(1\).svg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.background-image-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-image: url('../../../assets/inverted\ 1\ \(2\).svg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.slide-button:hover {
  background-color: #0056b3;
}

.testimonial-description {
  margin-bottom: 10px;
  text-align: left;
  color: #2A3342;
  font-size: 28px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 44px;
}
.testimonial-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 55%;
  margin: 45px auto;
  overflow: hidden; /* Add overflow: hidden to hide the partial slides */
  
}

.testimonial-slide {
  display: flex;
  width: 100%;
}


.testimonial-name {
  color: #333F51;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 32px;
}

.testimonial-designation {
  color: #556987;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}




@media only screen and (max-width: 900px) {
  .testimonial-slider{
    width: 95%  !important;
  }
  .testimonial-slide {
    flex-direction: column;
  }
  .column-left {
    flex-direction: unset;
  }
  .column-right {   
     align-items: center;
    padding-top: 30px;
  }
  .testimonial-description{
    font-size: 20px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .testimonial-slider {
    width: 100% !important;
  }

  .testimonial-slide {
    flex-direction: column;
  }

  .column-left,
  .column-right {
    align-items: center;
    padding: 20px; /* Adjust padding for better spacing on small screens */
  }

  .testimonial-description {
    font-size: 18px;
    line-height: 28px;
  }

  .top-left-image,
  .bottom-right-image {
    margin: 0; /* Adjust margin for better positioning on small screens */
  }

  .testimonial-name {
    font-size: 20px;
    line-height: 28px;
  }

  .testimonial-designation {
    font-size: 16px;
    line-height: 24px;
  }

  .testimonial-buttons {
    text-align: center;
  }

  .testimonial-bottom-image {
    justify-content: center;
  }
}

/* Additional media query for mid-sized screens */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .testimonial-slider {
    width: 95% !important;
  }
}
/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup Container */
  .popup-container {
    background: #ffffff;
    border-radius: 10px;
    width: 90%; /* Responsive width */
    max-width: 800px;
    height: 80%; /* Fixed height */
    max-height: 80%; /* Ensure it fits smaller screens */
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden; /* Prevent overflowing */
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .close-button img {
    width: 20px;
    height: 20px;
  }
  
  /* Popup Content */
  .popup-content {
    display: flex;
    flex: 1; /* Make it take full available height */
    gap: 20px;
    overflow: hidden; /* Prevent horizontal overflow */
  }
  
  /* Categories Section */
  .categories-section {
    flex: 1; /* Adjust based on available space */
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100%; /* Restrict to the container's height */
    padding-right: 10px; /* Avoid overlap with scrollbar */
  }
  
  .category {
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .category:hover {
    background: #e9ecef;
  }
  
  .category-heading {
    font-size: 16px;
    margin: 0;
  }
  
  .category-heading.active {
    color: #007bff;
    font-weight: bold;
  }
  
  .subheadings {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .prompt-button {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
  }
  
  .prompt-button:hover {
    background: #007bff;
    color: white;
  }
  
  /* Editor Section */
  .editor-section {
    flex: 2; /* Larger area for editor */
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: hidden;
  }
  
  .editor-section h4 {
    font-size: 18px;
    margin: 0;
  }
  
  .editor-textarea {
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    resize: none;
    overflow-y: auto; 
  }
  
  .use-prompt-button {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    align-self: flex-end;
    transition: background 0.3s ease;
  }
  
  .use-prompt-button:hover {
    background: #0056b3;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .popup-container {
      width: 95%; /* Slightly smaller width */
      height: 90%; /* More height for smaller screens */
      padding: 15px; /* Reduced padding */
    }
  
    .categories-section,
    .editor-section {
      flex: 1; /* Equal flex on smaller screens */
    }
  
    .editor-textarea {
      height: 150px; /* Smaller height for editor */
    }
  
    .use-prompt-button {
      font-size: 13px; /* Adjust button font size */
      padding: 8px 12px; /* Smaller padding */
    }
  }
  
  @media screen and (max-width: 480px) {
    .popup-container {
      flex-direction: column; /* Stack content vertically */
      gap: 15px; /* Reduced gap */
      overflow-y: auto; /* Allow scrolling for very small screens */
    }
  
    .popup-content {
      flex-direction: column; /* Stack categories and editor */
      gap: 10px;
    }
  
    .editor-textarea {
      height: 120px; /* Adjusted for very small screens */
    }
  
    .use-prompt-button {
      font-size: 12px; /* Smaller font for smaller screens */
      padding: 6px 10px;
    }
  }
  
.login-form-fields {
  display: flex;
  flex-direction: column;
}

.label-dropdown {
  text-align: left;

  width: 100%;
  color: #4285f4;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 65.5px; 
  letter-spacing: -0.4px;
}
.dropdowwn-form {
  display: flex;
  width: 400px;
  border-radius: 6px;
  border: 1px solid #a0a3bd;
  box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  border: 1px solid #a0a3bd;
  box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
  padding: 16px 20px 16px 8px;
}

select {
  border: 1px solid #A0A3BD;
  border-radius: 5px;
  width: 65%;
  height: 56px;
  display: flex;
  justify-content: left;
  padding: 15px;
}

.plag_board {
  align-items: center;
}
.plag_chart {
  max-width: 298px;
  margin-right: 50px;
}
.plag_content {
  /* background-color: red; */
}
.plag_content svg {
  margin-right: 30px;
}
.plag_content p {
  display: grid;
  /* justify-content: space-evenly; */
  grid-template-columns: 0.8fr 3fr 1fr;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.015em;
  color: #323232;
  margin: 15px 0;
}
.plag_content span {
  font-weight: 700 !important;
  font-size: 16px !important;
  letter-spacing: -0.015em;
  color: #323232;
}


.mobile-support-container {
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.box-a {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.tab-container {
  display: flex;
  width: 100%;
}

.tab-panel {
  flex: 1;
  display: none;
  padding: 16px;
  border-top: 1px solid #ccc;
}

.tab-panel.active {
  display: block;
}
.tab {
  width: 50%;
  color: #3b82f6 !important;
  font-size: 18px !important;
  font-weight: 700;
}
.back-icon {
  align-items: center;
  
}
.support-back-icon{
  margin-right: 8px;
}
.box-b {
  margin-top: 25px;
}
.subbox-a {
  margin-top: 25px;
}


input {
  flex: 1;
  padding: 6px;
  border: none;
  border-radius: 4px;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #3b82f6 !important;
}

.Subbox {
  width: Fill (380px);
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
}
.box-button {
  display: flex;
  align-items: center;
}

.Subbox .box-button img {
  padding-right: 5px;
}

.Subbox .box-button p {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #212121;
}

/* .button-wrapper {
    display: flex;
  } */

.Subbox {
  margin-right: 10px;
  box-shadow: 0 2px 500px rgba(123, 120, 120, 0.2);
  /* transition: box-shadow 0.3s ease-in-out; */
}

.Subbox:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tab-panel {
  width: 100%;
}
.search-box {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-bottom: 30px;
  background-color: #F5F5F5!important;
}
.Search-text-area{
  background-color: #F5F5F5!important;
}
.tab-1 {
  color: black;
}

.tab-1-1 {
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  /* color: #3b82f6!important; */
  border: 2px solid #3b82f6 !important ;
}
/* .MuiTabs-indicator {
    display: none;
  } */

.typo {
  color: #212121;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.typo-summary {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.accord {
  margin: 20px auto;
  box-shadow: 0 3px 100px rgba(117, 116, 116, 0.2) !important;
}
.larger {
  font-size: 40px;
}

.MuiPaper-root{
  border-radius:20px!important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display:none!important;
}
/* Quill Editor Toolbar Styles */
.ql-toolbar.ql-snow {
    border-bottom: 1px solid #DDDDDD !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

/* Hide any toolbar generated by the Editor component */
.ql-toolbar {
    display: none !important;
}

/* Quill Editor Container */
.ql-container.ql-snow {
    border-left: none !important;
    border-right: none !important;
    cursor: pointer;
}

/* Quill Header Button Styles */
.ql-header {
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px;
}

/* Quill Editor Main Content Area */
.ql-editor {
    white-space: pre-wrap !important; /* Preserves line breaks and spaces */
    word-break: break-word !important; /* Prevents text from overflowing */
    overflow-wrap: break-word !important; /* Handles long words correctly */
    line-height: 1.5 !important; /* Consistent line height for better readability */
    margin: 0 !important; /* Remove any extra margin */
    padding: 8px; /* Adds padding to text for a cleaner look */
    font-family: Arial, sans-serif; /* Ensures consistent font rendering */
    font-size: 14px; /* Set a consistent font size */
    color: #333; /* Text color */
 
}

/* Ensure paragraph styles within the editor */
.ql-editor p {
    line-height: 1.5 !important;
    margin: 0 !important;
    white-space: pre-wrap !important;
}

/* Placeholder Animation (Optional) */
@keyframes typing {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.animated-placeholder {
    animation: typing 2s steps(30) infinite;
    color: #999;
    font-style: italic;
}

/* Error Message Categories (Optional) */
.error-category-1 {
    color: red;
}

.error-category-2 {
    color: orange;
}

.error-category-3 {
    color: blue;
  }
.ql-formats {
    display: none !important;
}
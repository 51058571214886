/* index.css */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.homepage-container {
  padding-top: 95px;  
  text-align: center;
  box-sizing: border-box;
  background-color: white;
  min-height: 100vh;
  width: 100%;
}

.sec-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
}

@media only screen and (max-width: 600px) {
 
}
.FAQ-container {
  width: 70%;
  margin: auto;
  padding: 75px;
}


.accordian-contain {
  border-radius: 0 !important;
}

.css-ahj2mt-MuiTypography-root {
  text-align: left !important;
}
.FAQ-section{
  padding-top: 85px;
}

@media only screen and (max-width: 900px) {
  .FAQ-container {
    width: 100%;
    margin: auto;
    padding: 30px 20px;
  }
}

.heading-FAQ {
  color: var(--gray-900, #2A3342);
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  /* 125% */
  letter-spacing: -0.96px;
}

.Acc {
  box-shadow: 5px 10px #888888;
}
.my-account-container {
    background-color: #ffffff;
}

.rightpane-myaccount {
    height: 100vh;
    flex: 1 1;
    background-color: #ffffff;
    padding: 60px 40px 20px 90px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    overflow-y: auto;
    margin-left: 10%;
}

.my-account-head {
    color: #000;
    font-family: Poppins;
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: -0.58px;
    text-transform: capitalize;
}

.acc-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    padding-top: 28px;

}

.acc-head-pass {
    color: #A6A6A6;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    padding-top: 25px;
}

.acc-head {
    color: #A6A6A6;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    padding-top: 25px;
}

.acc-head-pass {
    color: #A6A6A6;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    padding: 0px;
}

.acc-field {
    display: flex;
    gap: 170px;
    align-items: center;
    padding-top: 20px;

}

.acc-fields {
    display: flex;
    gap: 238px;
    align-items: center;
    padding-top: 20px;

}

.acc-value {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
}

.acc-value-link {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    padding-top: 10px;
}

.acc-values {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
}

.acc-update {
    color: #3A3AF4;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: -0.28px;
    text-transform: capitalize;
}

.delete-account {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    bottom: 0;
    right: 0;
    padding: 25px 40px;
}

.delete-title {
    color: #F00;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
}

.delete-content {
    color: #A6A6A6;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
}


@media only screen and (max-width: 800px) and (min-width: 570px) {
    .acc-fields {
        gap: 220px;
    }

    .acc-field {
        gap: 140px;
    }

    .acc-values {
        width: 160px;
    }

}

@media only screen and (max-width: 550px) and (min-width: 401px) {
    .acc-fields {
        gap: 220px;
    }

    .acc-field {
        gap: 68px;
    }

    .acc-values {
        width: 160px;
    }

    .rightpane-myaccount {
        margin-left: 14%;
    }

    .my-account-head {
        font-size: 22px;
    }

    .acc-value {
        font-size: 14px;
    }

    .acc-head {
        font-size: 14px;
    }

    .acc-head-pass {
        font-size: 14px;
    }

    .acc-fields {
        gap: 95px;
    }

    .delete-account {
        align-items: flex-start;
        padding: 10px 0px;
    }

    .acc-title {
        font-size: 15px;
    }

    .acc-update {
        font-size: 11px;
    }

    .acc-value-link {
        font-size: 14px;
    }

    .acc-values {
        font-size: 12px;
    }

    .delete-title {
        color: #F00;
        font-family: Poppins;
        font-size: 16px;
    }

    .delete-content {
        font-size: 12px;
    }
}

@media only screen and (max-width: 400px) and (min-width: 270px) {
    .rightpane-myaccount {
        margin-left: 14%;
        padding: 60px 25px 20px 60p
    }

    .my-account-head {
        font-size: 22px;
        line-height: 22px;
    }

    .acc-field {
        gap: 45px;
    }

    .acc-value {
        font-size: 14px;
    }

    .acc-head {
        font-size: 14px;
    }

    .acc-head-pass {
        font-size: 14px;
    }

    .acc-fields {
        gap: 95px;
    }

    .delete-account {
        align-items: flex-start;
        padding: 10px 0px;
    }

    .acc-title {
        font-size: 15px;
    }

    .acc-update {
        font-size: 11px;
    }

    .acc-value-link {
        font-size: 14px;
    }

    .acc-values {
        font-size: 12px;
    }

    .delete-title {
        color: #F00;
        font-family: Poppins;
        font-size: 16px;
    }

    .delete-content {
        font-size: 12px;
    }
}
suggestion-card {
    transition: all 0.3s ease;
  }
  
  .suggestion-card:hover {
    transform: scale(1.05); /* Slightly enlarge the card */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }

  .expandable-content:hover {
    transform: scale(1.05); /* Slightly enlarge the content */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }
  
  /* Expandable content animations */
  .expandable-content {
    overflow: hidden;
    max-height: 0; /* Start with collapsed state */
    opacity: 0; /* Make the content invisible when collapsed */
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  }

  .expandable-content.expanded {
    max-height: 1000px; /* A large value to ensure the content can expand fully */
    opacity: 1; /* Make the content visible when expanded */
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Arrow rotation animation */
  .arrow-icon {
    transition: transform 0.3s ease;
  }
  
  /* Button hover effects */
  .action-button {
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
  }
  
  .action-button:hover {
    transform: translateY(-1px);
  }
  
  .action-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  
  .action-button:hover::after {
    transform: translateX(0);
  }
  
  /* Accept button specific styles */
  .accept-button {
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(58, 58, 244, 0.4);
    }
    70% {
      box-shadow: 0 0 0 6px rgba(58, 58, 244, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(58, 58, 244, 0);
    }
  }
  
  /* Nested suggestions animation */
  .nested-suggestion {
    animation: fadeIn 0.4s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Loading state animation */
  .loading {
    animation: shimmer 1.5s infinite linear;
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(58, 58, 244, 0.1) 50%,
      transparent 100%
    );
    background-size: 200% 100%;
  }
  
  @keyframes shimmer {
    from {
      background-position: -200% 0;
    }
    to {
      background-position: 200% 0;
    }
  }
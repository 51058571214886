.sign-up-container {
    padding-top: 165px;
    padding-bottom: 80px;
    margin: auto;
    text-align: center;
    background-image: url('../../../../assets/Frame\ 16.svg');
}

.sign-up-header {
    color: #000;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.or {
    color: #B0BAC3;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.sign-up-form {
    padding: 60px;
    width: 45%;
    margin: auto;
    border-radius: 40px;
    background: #FCFEFF;
    box-shadow: 6px 26px 8px 3px rgba(0, 0, 0, 0.25);
}

.form-label {
    color: #7C838A;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.form-input {
    width: 100%;
    height: 65px;
    padding: 10px;
    margin-bottom: 16px;
    border-radius: 20px;
    background: rgba(176, 186, 195, 0.40);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.50);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Next-button {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    background: #6B6B6B;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sign-up-button:hover {
    background-color: #45a049;
}

.password-validations {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.password-validations {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    gap: 15px;
}

.validation-item {
    display: flex;
    align-items: center;
    width: 31%;
}

.validation-item img {
    margin-right: 10px;
}

.validation-item span {
    color: #7C838A;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
}

.remember-forgot-container {
    display: flex;
    justify-content: space-between;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-style: none !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {

    padding: 16.5px 34px !important;
}

.login-section {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-text {
    color: #616161;
    font-size: 12.80px;
    font-family: 'poppins';
    font-weight: 400;
    word-wrap: break-word;
}

.login-link {
    color: #212121;
    font-size: 12.80px;
    font-family: 'poppins';
    font-weight: 900;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
    /* Adjust as needed */
    word-wrap: break-word;
}

/* //sign up css */

.login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;
    padding: 10px 18px 10px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-image: url("../../../../assets//google.svg");
    background-color: white;
    background-repeat: no-repeat;
}


.login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-with-google-btn:active {
    background-color: #eeeeee;
}

.login-with-google-btn:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

&:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}



@media only screen and (min-width: 901px) and (max-width: 1350px) {
    .sign-up-form {
        padding: 60px 40px;
        width: 60%;
    }

    .validation-item {
        width: 48%;
    }
}

@media only screen and (min-width: 500px) and (max-width: 900px) {
    .sign-up-form {
        padding: 60px 40px;
        width: 75%;
    }

    .validation-item {
        width: 90%;
    }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
    .sign-up-form {
        padding: 60px 40px;
        width: 75%;
    }

    .validation-item {
        width: 48%;
    }
}
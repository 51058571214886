.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}

.logo img {
  max-width: 80%;
  margin: auto;
  cursor: pointer;
}
.menu_icon {
  display: none;
  cursor: pointer;

}
.menu_box {
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.nav_menu {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(5, auto);
  padding: 0;
  margin: 0;
}

.nav_link {
  padding: 0 1.7rem;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: none;
  color: #000;
}
.nav_link.active {
  color: #3b82f6;
}

.nav_link:hover {
  color: #3b82f6;
}
.menu_box li:last-child .nav_link {
  background-color: #3b82f6;
  padding: 0.4rem 1.7rem;
  /* margin: 0 0.7rem; */
  color: #fff !important;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: none;
  border-radius: 0.2rem;
}

.signin_link {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
.signin_link:hover {
  color: #fff;
}
.auth_btn button {
  border-radius: 4px;
  padding: 10px 20px 12px 20px;
  background: #1472e5;
  border: none;
  cursor: pointer;
}
.auth_btn .signout_link {
  border-radius: 4px;
  padding: 10px 20px 12px 20px;
  background: #fff;
  border: 1px solid;
  cursor: pointer;
  color: #1472e5;
  font-size: 16px;
  font-weight: 700;
}
@media screen and (max-width: 960px) {
  .logo {
    cursor: pointer;
    width: 180px;
    height: 180px;
    margin-bottom: 10px;
  }
  .NavbarItems {
    position: relative;
  }
  .menu_box {
    display: block;
  }
  .nav_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -106%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-top: 0;
  }
  .nav_menu.active {
    list-style-type: none;
    background-color: #f3faff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 99;
    /* align-items: center; */
  }
  .nav_link {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-link:hover {
    color: #000;
  }
  .menu_icon {
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    /* background-color: red; */
    /* transform: translate(-100%, 64%); */
    font-size: 1.8rem;
  }
  .auth_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
}

.main-footer-container{
background-color: white;
width: 100%;
margin-top: 80px;
}

.footer-container {
    display: flex;
    flex-direction: column;
    background-color: #252E3B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 45px 80px;
}

.footer-part-1 {
    display: flex;
    justify-content: space-between;

}

.footer-description {
    text-align: left;
    width: 52%;
    color: white;
    font-size: 16px;
    font-family: 'Sen';
    font-weight: 400;
    line-height: 22px;
}

.footer-menu-items {
    color: white;
    display: -webkit-flex;
    gap: 50px;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.contact-details {
    width: 98%;
    padding-bottom: 8px;
    color: white;
    TEXT-ALIGN: LEFT;
    DISPLAY: FLEX;
    JUSTIFY-CONTENT: END;
}

.main-footer-menu {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 6px;
}


.f-sub-menu-item {
    cursor: pointer;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.f-menu-item {
    cursor: pointer;
}

.cta-button {
    cursor: pointer;
    border-radius: 6px;
    background: #3B82F6;
    box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
    color: var(--primary-050, #F0FDF4);
    width: max-content;
    padding: 10px 20px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */

}

.footer-col-1 {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.footer-logo {
    width: 150px;
    height: 66px;
}

.footer-part-2 {
    color: white;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    /* 150% */
}

.cursor {
    cursor: pointer;
}

.footer-col-2 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.submenu-footer {
    background-color: white;
    color: black;
    font-size: 15px;
    font-family: 'Sen';
    font-weight: 400;
    line-height: 22px;
    padding: 15px 10px 25px 10px;
    border-radius: 2px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 900px) {

    .footer-part-1 {
        display: unset;

    }

    .footer-container {
        height: auto;
        padding: 45px 30px;
    }

    .footer-menu-items {
        gap: 25px;
        margin: 20px 0px;

    }

    .footer-description {
        width: 100%;
    }

    .footer-part-2 {
        text-align: left;
    }

    .footer-col-2 {
        text-align: left;
    }
    .contact-details {
        width: 100%;
       
        JUSTIFY-CONTENT: start;
    }
}

@media only screen and (max-width: 1125px) {
    .footer-container {
        height: auto;
    }

    .footer-menu-items {
        gap: 30px;
        margin: 20px 0px;
        flex-wrap: wrap;
    }

    .footer-description {
        width: 85%;
    }
    .contact-details {
        width: 100%;
       
        JUSTIFY-CONTENT: start;
    }
}
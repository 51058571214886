
.subscribe-container{
    padding: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cont-3{
    justify-content: center;
    flex-direction: column;
    border: 2px solid #3b82f6;
    border-radius: 34px;
    padding: 28px;
    background-color: #fafafa;
    
   
}
.hr{
    color: #c2c2c2!important;
}
.subs-list-1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.subs-list-2{
    display: flex;
    align-items: center;
    padding: 6px 5px;
    
   
}
.arrow-img{
margin-right: 10px;
}
.bold{
    font-weight: 700;
    font-size: 28px;
    color: black;
}
.sublist{
    font-size: 16px;
    color: #424242;
    font-weight: 600;
}
.subs-head{
    font-size: 30px;
    font-weight: 700;
    color :  #3b82f6;
}
.subs-para{
    font-size: 16px;
    font-weight: 500;
    color :  #424242;
}
.contain-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0px;
}
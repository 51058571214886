.section-two {
  background-image: url('../../../assets/Frame\ 16.svg');

}

.container-homenew {
  width: 100%;

}

.innerHome-container {
  max-width: 1799px;
  margin: 0 auto;
  /* padding: 60px 165px; */
  display: flex;
  justify-content: center;
  gap: 30px;

}

.text-home p {
  display: flex;
  text-align: center;
  color: white;
  font-size: 35px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 15px;
}


.home-button1 {
  height: 40px;
  width: 150px;
  padding: 3px 10px;
  background-color: #3b82f6;
  color: #f0fdf4;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
  border-radius: 6px;
  /* width: auto !important; */
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .inner-container {
    flex-direction: column;
    /* Stack content vertically on smaller screens */
    text-align: center;
  }

  .text-home p {
    padding: 5px 0;
    display: block;
    font-size: 30px;
    font-weight: 700;
    margin: 5px;

    /* Add spacing above and below text */
  }
}

@media only screen and (max-width: 900px) {

  .innerHome-container {
    padding: 50px 5px;
  }

}


.GrammarCorrections {
    font-size: 18px !important;
    color: #040404 !important;
    font-weight: 500 !important;
    font: Source Sans Pro;
    padding: 10px !important;
    margin-left: -4% !important;
    margin-top: 3px !important;


}

.ViewPlagiarism {
    font-size: 18px !important;
    color: #040404 !important;
    font-weight: 500 !important;
    font: Source Sans Pro;
    padding: 10px !important;
    margin-left: 10px !important;
    background-color: #3A3AF4 !important;
    color: #FFFFFF !important;
    margin-top: 3px !important;
}

.yourAssistant {
    font-size: 18px !important;
    color: #FFFFFF !important;
    font: Source Sans Pro !important;
    font-weight: 500 !important;
    background-color: #3A3AF4 !important;
    /* width: 16% !important; */
    /* padding: 10px !important; */
    margin-top: 3px !important;
}
.user_profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #B1CEFB;
  cursor: default;
}
.user_profile_name {
  /* font-size: 18px; */
  cursor: default;
}
.user_profile_name_desc {
  margin-top: 0;
  font-size: 0.8vw;
  color: #797979;
  cursor: default;
}

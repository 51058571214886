
.mobile-profile-container {
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.user_profile_Pic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color:#D8E6FD;
  cursor: default;
  color: white;
  font-size: 40px;
  font-weight: 700;
}
.user_profile_Pic_HOMEPAGE{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color:#3A3AF4;
  cursor: default;
  color: white;
  font-size: 20px;
  font-weight: 700;
}
.box-1 {
  display: flex;
  align-items: center;
}

.back-icon {
  cursor: pointer;
  margin-right: 10px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
}

.box-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.box-3 h2 {
  font-size: 24px;
  font-weight: bold;
  color: #3b82f6;
  
}
.premium-span {
  margin-left: 20px;
  margin-top: 14px;
}
.premium-icon {
  width: 50px;

}

/* .premium {
  align-items: center;
} */
.box-3 p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #616161;
}
.profile-mail {
  font-size: 18px;
  font-weight: 500;
  margin-top: -8px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #000000;
}
.profile-name {
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 0em;
  text-align: center;
}
p {
  margin-top: 10px;
  font-size: 14px;
  color: #808080;
}

.box-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #3b82f6;
  border-radius: 35px;
  padding: 15px;
  margin: auto;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.subBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.subBox .class-1 {
  display: flex;
  align-items: center;
}

.subBox .class-1 img {
  padding-right: 8px;
}

.subBox .class-1 p {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #212121;
}

.subBox .class-2 img {
  width: 20px;
  height: 20px;
}

.user_profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #D8E6FD;
  cursor: default;
  color: white;
  font-size: 30px;
}
.user_profile_name {
  /* font-size: 18px; */
  cursor: default;
}
.user_profile_name_desc {
  margin-top: 0;
  font-size: 0.8vw;
  color: #797979;
  cursor: default;
}

.EditPic {
  margin: -15px 0px 25px 39px;
}
.mob-p {
  color: #3b82f6 !important;
}
